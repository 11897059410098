import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Input, Button, message, Segmented, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Google from "../constant/images/button.svg";
import { LoginUserData, VerifyOTP, CreateUserData } from "./../reducer/thunks"; // Import the CreateUserData action
import { signInWithGooglePopup, auth } from "../firebase/firebaseconfig";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";

const LoginModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [form] = Form.useForm();
  const [activeSegment, setActiveSegment] = useState("Login");
  const [loaderVisible, setloaderVisible] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    mobilenumber: "",
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otpError, setOtpError] = useState("");

  const {
    loading: getprofileUserLoading,
    loginerrors: getprofileUserError,
    otpVerificationResponse: otpVerification,
  } = useSelector((state) => state.otpVerificationResponse);

  const { loginData: loginresponce,    
    loginerror: getloginresponceError,
  } = useSelector((state) => state.loginData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateRecaptcha = () => {
    // Create a new RecaptchaVerifier instance
    return (window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {}
    ));
  };



  const handleSendOtp = () => {
    const { email, password, mobilenumber } = loginData;
    if ((email && password) || (mobilenumber && mobilenumber.length === 10)) {
      let body = {};
      if (email && password) {
        body = { email, password };
        // Call API to send OTP

        dispatch(LoginUserData(body)); // Replace sendOTPAction with your actual action
      } else if (mobilenumber) {
        // Call API to send OTP
        body = { mobilenumber: mobilenumber };

        dispatch(LoginUserData(body)); // Replace sendOTPAction with your actual action
        setOtpSent(true);
      }
    } else {
      message.error(
        "Please enter valid email and password or a 10-digit mobile number with OTP"
      );
    }
  };

  const handleLogin = () => {
    const { email, password, mobilenumber } = loginData;
    if (
      (email && password) ||
      (mobilenumber && mobilenumber.length === 10 && otpValue)
    ) {
      let body = {};
      if (email && password) {
        body = { email: email, otp: otpValue };
        dispatch(VerifyOTP(body)); // Dispatch action to verify OTP
      } else if (mobilenumber && otpValue) {
        body = { mobilenumber: mobilenumber, otp: otpValue };
        dispatch(VerifyOTP(body)); // Dispatch action to verify OTP
      }
    } else {
      message.error(
        "Please enter valid email and password or a 10-digit mobile number with OTP"
      );
    }
  };

  // Handle OTP verification response
  useEffect(() => {
    if (otpVerification) {
      console.log(otpVerification, "otpVerification");
      if (otpVerification !== "Invalid OTP") {
        message.success(otpVerification.message, 5);
        if (otpVerification.UserType === "3") {
          message.success("Success", 5); // Display success message for 5 seconds
          console.log(otpVerification);
          localStorage.setItem("userId", otpVerification.userId);
          onClose();
          window.location.reload();
          window.location.href = "/";
        } else {
          message.success("Not exist as User", 5); // Display success message for 5 seconds
        }
        // Perform actions after successful OTP verification
      } else {
        message.error(otpVerification, 5);
      }
    }
  }, [otpVerification]);

  useEffect(() => {
    console.log(loginresponce,'loginresponce');

    if (loginresponce?.userId) {
      message.success("Success", 5); // Display success message for 5 seconds
      console.log(loginresponce);
      localStorage.setItem("userId", loginresponce.userId);
      onClose();
      window.location.reload();
      window.location.href = "/";
    }
  }, [loginresponce?.userId]);

  useEffect(() => {
    console.log(getloginresponceError,'loginresponce');
    if(getloginresponceError?.message === "Verification SMS sent successfully" && !getloginresponceError.success){
      setOtpSent(true);
      message.success(getloginresponceError.message, 5); // Display success message for 5 seconds

    }else if(getloginresponceError?.message === "Invalid credentials" && !getloginresponceError.success){
      message.error("Credentials that are invalid You can create a new account.", 5); // Display success message for 5 seconds

      const { email, password, mobilenumber } = loginData;

         form.setFieldsValue({
          email: email,
          password: password,
   
      // Add other fields as needed
    });
      setActiveSegment("Register")
    }
   
   
  }, [getloginresponceError?.message]);

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    // const response = await sendOTPviaSMS("+919629283625");
    console.log(response);
    const { email, password, mobilenumber } = loginData;

    let body = {
      email: response.user.email,
      password: "",
      google_signin: response.user.emailVerified,
    };

    dispatch(LoginUserData(body)); // Replace sendOTPAction with your actual action
  };

  const {
    loading: createUserLoading,
    error: createUserError,
    userData: createUserResponse,
  } = useSelector((state) => state.userData);

  const handleRegister = async (values) => {
    setloaderVisible(true);
    if (
      values.email &&
      values.password &&
      values.firstName &&
      values.lastName &&
      values.mobileNumber
    ) {
      let body = {
        firstname: values.firstName,
        lastname: values.lastName,
        mobilenumber: values.mobileNumber,
        email: values.email,
        password: values.password,
        UserType: "3",
      };

      // Dispatch the CreateUserData action
      await dispatch(CreateUserData(body));
      setloaderVisible(false);
    } else {
      setloaderVisible(false);
      // Handle validation errors or show a message to the user
      message.error("Please enter valid data for registration");
    }
  };

  useEffect(() => {
    if (createUserResponse) {
      // If registration was successful, show the success modal
      // setSuccessModalVisible(true);
      message.success(createUserResponse, 5); // Display error message for 5 seconds
      console.log(createUserResponse);
      onClose();
      localStorage.setItem("userId", createUserResponse.userId);
      onClose();
      window.location.reload();
      window.location.href = "/";
    }
    if (createUserError) {
      message.error(createUserError, 5); // Display error message for 5 seconds
    }
  }, [createUserResponse, createUserError]);

  const logGoogleUsers = async () => {
    const response = await signInWithGooglePopup();
    // const response = await sendOTPviaSMS("+919629283625");
    console.log(response);

    let body = {
      firstname: response.user.displayName,
      lastname: response.user.displayName,
      mobilenumber: response.user.uid,
      email: response.user.email,
      password: response.user.metadata.createdAt,
      UserType: "3",
    };

    await dispatch(CreateUserData(body));
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="350px"
      footer={null}
      style={{ marginTop: "3%" }}
    >
      <div className="segmented-container">
        {/* <Segmented
          options={[
            {
              label: (
                <div className="segmented-option">
                  <LoginOutlined />
                  <span>Login</span>
                </div>
              ),
              value: "Login",
            },
            {
              label: (
                <div className="segmented-option">
                  <LogoutOutlined />
                  <span>Register</span>
                </div>
              ),
              value: "Register",
            },
          ]}
          value={activeSegment}
          onChange={setActiveSegment}
          className="custom-segmented"
          block
        /> */}
      </div>
      {activeSegment === "Login" ? (
        <>
          <h4 className="text-center font-weight-bold head-control mt-4">
            Login
          </h4>
          <p className="text-center p-text mb-4">
            Choose one of the options below
          </p>
          <Form onFinish={handleLogin} initialValues={loginData}>
            {!otpSent ? (
              <>
                {loginData.mobilenumber.length !== 10 && (
                  <>
                    <Form.Item name="email">
                      <Input
                        name="email"
                        className="form-control text-input"
                        placeholder="Email"
                        onChange={handleInputChange}
                      />
                    </Form.Item>

                    <Form.Item name="password">
                      <Input
                        name="password"
                        className="form-control text-input custom-password-input"
                        placeholder="Password"
                        onChange={handleInputChange}
                        style={{ backgroundColor: "transparent" }}
                      />
                    </Form.Item>
                  </>
                )}
                <div className="mb-3">
                  {loginData.mobilenumber.length !== 10 && (
                    <p className="text-center p-text">OR</p>
                  )}

                  <div id="recaptcha-container"></div>

                  <div className="d-flex justify-content-center">
                    <img
                      onClick={
                        activeSegment === "Login"
                          ? logGoogleUser
                          : logGoogleUsers
                      }
                      // className="text-center"
                      height={"44px"}
                      alt=""
                      src={Google}
                    />
                  </div>
                  <p className="text-center p-text">OR</p>
                </div>
                {loginData.password.length < 1 && (
                  <Form.Item
                    name="mobilenumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Mobile Number",
                      },
                    ]}
                  >
                    <Input
                      name="mobilenumber"
                      className="form-control text-input"
                      placeholder="Mobile Number"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                )}

                <div className="d-flex justify-content-center pt-2">
                  <Button
                    type="primary"
                    className="btn button w-75 h-25"
                    onClick={handleSendOtp}
                  >
                    {loginData.password.length < 1 ? "Send OTP" : "Login"}
                  </Button>
                </div>

                <div className="d-flex justify-content-center mt-4">
                     <Button className="w-100" onClick={()=>{
                      setActiveSegment("Register")
                     }}>
                      Create Your Local Heros Account
                     </Button>
                  </div>
              </>
            ) : (
              <>
                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please enter OTP",
                    },
                  ]}
                >
                  <Input
                    name="otp"
                    className="form-control text-input"
                    placeholder="Enter OTP"
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                </Form.Item>

                {otpError && <p className="text-danger">{otpError}</p>}

                <div className="d-flex justify-content-center pt-2">
                  <Button
                    type="primary"
                    className="btn button w-75 h-25"
                    onClick={handleLogin}
                  >
                    Verify OTP
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      ) : (
        <>
          <h4 className="text-center font-weight-bold head-control mt-4 mb-4">
            Register
          </h4>

          <Form form={form} onFinish={handleRegister}>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <Input
                className="form-control text-input"
                placeholder="First Name"
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <Input
                className="form-control text-input"
                placeholder="Last Name"
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input className="form-control text-input" placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="mobileNumber"
              rules={[
                { required: true, message: "Please enter your mobile number" },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please enter a 10-digit mobile number",
                },
              ]}
            >
              <Input
                className="form-control text-input"
                placeholder="Mobile Number"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
                {
                  min: 6,
                  message: "Password must be at least 6 characters long",
                },
              ]}
            >
              <Input
                className="form-control text-input"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <div style={{ textAlign: "center" }}>
                {loaderVisible ? (
                  <Spin />
                ) : (
                  <Button
                    type="primary"
                    className="btn button w-75 h-25 mt-4"
                    htmlType="submit"
                  >
                    Register
                  </Button>
                )}
              </div>
            </Form.Item>
            <p className="text-center p-text">OR</p>

            <img
              onClick={
                activeSegment === "Login" ? logGoogleUser : logGoogleUsers
              }
              // className="text-center"
              height={"44px"}
              alt=""
              src={Google}
            />
          </Form>
          <div className="d-flex justify-content-center mt-4">
                     <Button className="w-100" onClick={()=>{
                      setActiveSegment("Login")
                     }}>
                      Already have an account? Sign in
                     </Button>
                  </div>
        </>
      )}
    </Modal>
  );
};

export default LoginModal;
