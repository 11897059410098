import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { AddAddressFetch } from "./../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import CardDrawerMap from "../pages/CardDrawerMap";

const AddressModel = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [drawer, setDrawer] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const { addaddressres: AddAddressAPIRes } = useSelector(
    (state) => state.addaddressres
  );

  useEffect(() => {
    if (AddAddressAPIRes?.success) {
      message.success(AddAddressAPIRes.message);
      onClose(); // Close modal on success
      form.resetFields(); // Clear form fields
    } else if (AddAddressAPIRes !== null)  {
      message.error(AddAddressAPIRes);
    //   form.resetFields(); // Clear form fields
    }
  }, [AddAddressAPIRes]);

  const handleAddAddress = async (values) => {
    const address = {
      city: values.city,
      street: values.street,
      pinCode: values.pinCode,
      typeAddress: values.typeAddress,
      companyName: values.companyName,
      fullName: values.fullName,
      phone: values.mobile,
      email: values.email,
      state: "Karnataka", // This can be dynamic if needed
      userId: localStorage.getItem("userId"),
      lat: String(latitude), // Convert latitude to string
      lng: String(longitude), // Convert longitude to string
    };

    await dispatch(AddAddressFetch(address));
  };

  const handleClear = () => {
    form.resetFields(); // Clear all form fields
  };

  const handleAddressSelect = (val) => {
    form.setFieldsValue({
      city: val.city || "",
      street: val.fullAddress || "",
      pinCode: val.pincode || "",
      typeAddress: val.addressType || "",
      FlatNo: val.doorFlatNo || "",
      landmark: val.landmark || "",
    });
    setLatitude(val.location.lat);
    setLongitude(val.location.lng);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          handleClear(); // Clear form on modal close
          onClose();
        }}
        width="850px"
        footer={null}
        style={{ marginTop: "3%" }}
      >
        <h4 className="text-center font-weight-bold head-control mt-4 mb-4">
          Add Address
        </h4>

        <Form form={form} onFinish={handleAddAddress}>
          <div className="row">
            <Form.Item
              name="companyName"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter company name" }]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>

            <Form.Item
              name="fullName"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="mobile"
              className="col-md-4"
              rules={[
                { required: true, message: "Please enter mobile number" },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>

            <Form.Item
              name="email"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter email" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="city"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter city" }]}
            >
              <Input placeholder="City" />
            </Form.Item>
            
            <Form.Item
            onClick={()=>setDrawer(!drawer)}
              name="street"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter street" }]}
            >
              <Input placeholder="Street" />
            </Form.Item>

            <Form.Item
              name="pinCode"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter pin code" }]}
            >
              <Input placeholder="Pin Code" />
            </Form.Item>

            <Form.Item
              name="typeAddress"
              className="col-md-4"
              rules={[{ required: true, message: "Please enter address type" }]}
            >
              <Input placeholder="Address Type" />
            </Form.Item>
          </div>

          <div className="text-center">
            <Button type="primary" htmlType="submit" className="mt-3">
              Add Address
            </Button>
            <Button className="mt-3 ml-2" onClick={handleClear}>
              Clear
            </Button>
            <Button className="mt-3 ml-2" onClick={() => setDrawer(true)}>
              Select on Map
            </Button>
          </div>
        </Form>
      </Modal>

      <CardDrawerMap
        openDrawer={drawer}
        onClose={() => setDrawer(false)}
        onPass={handleAddressSelect}
      />
    </>
  );
};

export default AddressModel;
